


























































































































































































































/* eslint-disable no-param-reassign */
/* eslint-disable vue/no-mutating-props */
/* eslint-disable no-prototype-builtins */
import { defineComponent, ref, reactive, computed, watch } from '@vue/composition-api';
import useTeamDoc from '@/composables/useTeamDoc';
import { useUserActions, useUserGetters } from '@/store';

interface IDraftData {
  problem?: string;
  solution?: string;
  innovation?: string;
  user?: string;
  onePitch?: string;
  elevatorPitch?: string;
  finalDraft?: boolean;
}

export default defineComponent({
  name: 'MProjectContent',
  components: {
    ATextArea: () => import('@/components/atoms/ATextArea.vue')
  },
  props: {
    value: {
      required: true,
      type: Object
    },
    adkType: {
      type: String,
      default: ''
    },
    isUpdating: {
      type: Boolean,
      default: false
    },
    isSaving: {
      type: Boolean,
      default: false
    },
    isMonitor: {
      type: Boolean,
      default: false
    },
    studentId: {
      type: String,
      default: ''
    }
  },
  setup(props: any, ctx: any) {
    const { updateAdkData } = useUserActions(['updateAdkData']);
    const { getTeamDoc }: any = useTeamDoc();
    const { getUser } = useUserGetters(['getUser']);

    const state: IDraftData = reactive({
      problem: '',
      solution: '',
      innovation: '',
      user: '',
      onePitch: '',
      elevatorPitch: ''
    });
    const teamDoc: any = ref();
    const isLoading = ref<boolean>(false);
    const totalDrafts = ref<number>(0);
    const disabledPastDraft = ref<number>(0);
    const success = ref<boolean>(false);
    const isSavingDraft = ref<boolean>(false);
    const isSavingFnDraft = ref<boolean>(false);
    const finalDraftSaved = ref<string>('Draft');
    const unmakeFD = ref<number>(0);
    const display = ref<number>(1);
    const unmaking = ref<boolean>(false);

    const programDoc = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const adkData = computed(() => {
      return programDoc.value?.data?.adks?.find(a => a.name === props.adkType);
    });

    const index = programDoc.value.data.adks.findIndex(obj => {
      if (obj) {
        return obj.name === props.adkType;
      }
      return false;
    });

    const user: any = computed({
      get: () => getUser.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const fieldIndex = computed(() => {
      return adkData.value.fields.findIndex(f => f.name === 'm-project-content');
    });

    const fieldValue = computed({
      get: () => adkData.value.fields[fieldIndex.value].value,
      set: newVal => {
        ctx.emit('fieldValue', newVal);
      }
    });

    const isValue = computed(() => {
      if (fieldValue.value?.valueDrafts?.length) {
        return true;
      }
      return false;
    });

    const isValueDraft = computed(() => {
      if (fieldValue.value?.valueDrafts?.length) {
        return true;
      }
      return false;
    });

    const currentPage = computed(() => {
      return ctx.root.$route.params.page;
    });

    const teamAdkIndex = computed(() => {
      return teamDoc.value.adks?.findIndex(
        a => a.name.toLowerCase() === props?.adkType?.toLowerCase()
      );
    });

    const setValidation = val => {
      return val.join('|');
    };

    const getSelectedDraftByDraftNo = (draft: number) => {
      return teamDoc?.value?.adks[teamAdkIndex.value]?.valueDrafts[draft - 1];
    };

    const setState = () => {
      const isIdeateADK = adkData?.value?.builderType === 'ideate' || props?.adkType === 'ideate';
      if (isIdeateADK) {
        state.problem = '';
        state.solution = '';
        state.innovation = '';
        state.user = '';
        delete state.onePitch;
        delete state.elevatorPitch;
      } else {
        state.onePitch = '';
        state.elevatorPitch = '';
        delete state.problem;
        delete state.solution;
        delete state.innovation;
        delete state.user;
      }
    };

    const setInitialData = () => {
      const isDraftDataAvailable =
        teamDoc?.value?.adks?.[teamAdkIndex.value]?.valueDrafts?.length >= 1;
      const isIdeateADK = adkData?.value?.builderType === 'ideate' || props?.adkType === 'ideate';
      const isPitchADK = adkData?.value?.builderType === 'pitch' || props?.adkType === 'pitch';

      totalDrafts.value = isDraftDataAvailable
        ? teamDoc.value.adks[teamAdkIndex.value].valueDrafts.length
        : 0;
      display.value = totalDrafts.value || 1;
      disabledPastDraft.value = 0;
      unmakeFD.value = 0;
      finalDraftSaved.value = 'Draft';
      setState();

      if (isDraftDataAvailable) {
        const selectedDraft = getSelectedDraftByDraftNo(totalDrafts.value);
        if (isIdeateADK) {
          state.problem = selectedDraft?.problem;
          state.solution = selectedDraft?.solution;
          state.innovation = selectedDraft?.innovation;
          state.user = selectedDraft?.user;
          if (selectedDraft?.finalDraft) {
            disabledPastDraft.value = 1;
            unmakeFD.value = 1;
            finalDraftSaved.value = 'Final Draft';
          }
        } else if (isPitchADK) {
          state.onePitch = selectedDraft?.onePitch;
          state.elevatorPitch = selectedDraft?.elevatorPitch;
          if (selectedDraft?.finalDraft) {
            disabledPastDraft.value = 1;
            unmakeFD.value = 1;
            finalDraftSaved.value = 'Final Draft';
          }
        }
      }
    };

    const loadInitialData = async () => {
      isLoading.value = true;
      teamDoc.value = await getTeamDoc({
        program_id: ctx.root.$route.params.programId || programDoc.value?.data.program_id,
        user_id: props.isMonitor ? props.studentId : user?.value?._id?.toString(),
        public_id: props?.value?.data?._id
      });
      setInitialData();
      isLoading.value = false;
    };

    loadInitialData();

    const showDraft = (draft: number) => {
      totalDrafts.value =
        (fieldValue.value?.valueDrafts && fieldValue.value?.valueDrafts?.length) || 0;
      const selectedDraft = getSelectedDraftByDraftNo(draft);
      if (selectedDraft) {
        const isIdeateADK = adkData?.value?.builderType === 'ideate' || props?.adkType === 'ideate';
        if (isIdeateADK) {
          state.problem = selectedDraft?.problem;
          state.solution = selectedDraft?.solution;
          state.innovation = selectedDraft?.innovation;
          state.user = selectedDraft?.user;
        } else {
          state.onePitch = selectedDraft?.onePitch;
          state.elevatorPitch = selectedDraft?.elevatorPitch;
        }
      }
      display.value = draft;

      if (totalDrafts.value !== draft) {
        disabledPastDraft.value = 1;
      } else {
        disabledPastDraft.value = 0;
      }

      if (selectedDraft && selectedDraft?.finalDraft) {
        finalDraftSaved.value = 'Final Draft';
        unmakeFD.value = 1;
        disabledPastDraft.value = 1;
      } else {
        finalDraftSaved.value = 'Draft';
        unmakeFD.value = 0;
        disabledPastDraft.value = 0;
      }
    };

    const updateTeamADKData = async data => {
      const teamRes = await updateAdkData({
        program_id: teamDoc?.value?._id?.toString(),
        document_type: 'ProgramTeam',
        data: { ...data }
      });
      teamDoc.value = teamRes.data;
    };

    const saveDraft = async (draft: number) => {
      isSavingDraft.value = true;
      totalDrafts.value =
        (fieldValue.value?.valueDrafts && fieldValue.value?.valueDrafts?.length) || 0;
      state.finalDraft = false;
      if (totalDrafts?.value === 0) {
        if (!fieldValue.value.valueDrafts) fieldValue.value.valueDrafts = [];
        fieldValue.value.valueDrafts.push(state);
      } else {
        fieldValue.value.valueDrafts[draft - 1] = state;
      }
      ctx.emit('update-adk');
      const data = {
        name: props.adkType,
        valueDrafts: fieldValue.value.valueDrafts
      };
      await updateTeamADKData(data);
      showDraft(draft);
      isSavingDraft.value = false;
    };

    const addNewDraft = () => {
      const isIdeateADK = adkData?.value?.builderType === 'ideate' || props?.adkType === 'ideate';
      const stateData: IDraftData = {};
      if (isIdeateADK) {
        stateData.problem = state.problem;
        stateData.solution = state.solution;
        stateData.innovation = state.innovation;
        stateData.user = state.user;
      } else {
        stateData.onePitch = state.onePitch;
        stateData.elevatorPitch = state.elevatorPitch;
      }
      stateData.finalDraft = false;
      fieldValue.value.valueDrafts.push(stateData);
    };

    const finalDraft = async (draft: number) => {
      isSavingFnDraft.value = true;
      totalDrafts.value =
        (fieldValue.value?.valueDrafts && fieldValue.value?.valueDrafts?.length) || 0;
      state.finalDraft = true;
      fieldValue.value.valueDrafts[draft - 1] = state;
      if (totalDrafts?.value === draft) {
        addNewDraft();
      }
      ctx.emit('update-adk');
      const data = {
        name: props.adkType,
        valueDrafts: fieldValue.value.valueDrafts
      };
      await updateTeamADKData(data);
      showDraft(draft);
      isSavingFnDraft.value = false;
    };

    const unmakeFinalDraft = async (draft: number) => {
      unmaking.value = true;
      fieldValue.value.valueDrafts[draft - 1].finalDraft = false;
      ctx.emit('update-adk');
      const data = {
        name: props.adkType,
        valueDrafts: fieldValue.value.valueDrafts
      };
      await updateTeamADKData(data);
      showDraft(draft);
      unmaking.value = false;
    };

    watch(currentPage, val => {
      if (val) {
        loadInitialData();
      }
    });

    watch(props, val => {
      if (val.adkType && props.isMonitor) {
        loadInitialData();
      }
    });

    return {
      state,
      unmaking,
      showDraft,
      unmakeFinalDraft,
      currentPage,
      isSavingDraft,
      finalDraft,
      isSavingFnDraft,
      status: 'true',
      setValidation,
      saveDraft,
      success,
      isValue,
      isValueDraft,
      showInstructions: 'true',
      finalDraftSaved,
      display,
      adkData,
      fieldIndex,
      programDoc,
      index,
      disabledPastDraft,
      unmakeFD,
      totalDrafts,
      isLoading
    };
  }
});
